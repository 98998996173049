import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InstantSearch, connectHits, Index, Configure, SearchBox } from "react-instantsearch-dom";

import { Container, H2 } from "@util/standard";
import { useStore } from "@state/store";
import Layout from "@shared/layout";
import { isBrowser, searchClient } from "@util/helper";
import SearchResultPreview from "@components/searchResults/searchResultPreview";
import { Button } from "@components/global";
import SEO from "@shared/seo";
import { useCheckScreenWidth } from "@util/hooks";

const HiddenSearchBar = styled(SearchBox)`
  /* display: none; */
  visibility: hidden;
`;

const SearchResults = () => {
  const { headerSearch } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();
  const [hitsVisible, setHitsVisible] = useState(false);
  const FeedYourLifeHits = ({ hits }: any) => {
    if (hits.length > 0 && headerSearch !== "") {
      setHitsVisible(true);
    }

    return (
      <>
        {headerSearch !== "" && (
          <Container flexDirection="column">
            <Container flexDirection="column">
              {hits.map((hit, index) => {
                const isLast = index === hits.length - 1 && hits.length > 1;

                return <SearchResultPreview data={hit} noBorder={isLast} key={hit._id} />;
              })}
            </Container>
          </Container>
        )}
      </>
    );
  };

  const BlogHits = ({ hits }: any) => {
    if (hits.length > 0 && headerSearch !== "") {
      setHitsVisible(true);
    }

    return (
      <>
        {headerSearch !== "" && (
          <Container flexDirection="column">
            <Container flexDirection="column">
              {hits.map((hit, index) => {
                if (hit.category == null) {
                  return null;
                }

                return <SearchResultPreview data={hit} key={hit._id} />;
              })}
            </Container>
          </Container>
        )}
      </>
    );
  };

  const ProductHits = ({ hits }: any) => {
    if (hits.length > 0 && headerSearch !== "") {
      setHitsVisible(true);
    }

    return (
      <>
        {headerSearch !== "" && (
          <Container flexDirection="column">
            <Container flexDirection="column">
              {hits.map((hit, index) => {
                return <SearchResultPreview data={hit} key={hit._id} />;
              })}
            </Container>
          </Container>
        )}
      </>
    );
  };

  const RecipeHits = ({ hits }: any) => {
    if (hits.length > 0 && headerSearch !== "") {
      setHitsVisible(true);
    }

    return (
      <>
        {headerSearch !== "" && (
          <Container flexDirection="column">
            <Container flexDirection="column">
              {hits.map((hit, index) => {
                return <SearchResultPreview data={hit} key={hit._id} />;
              })}
            </Container>
          </Container>
        )}
      </>
    );
  };

  const CustomFeedYourLifeHits = connectHits(FeedYourLifeHits);
  const CustomBlogHits = connectHits(BlogHits);
  const CustomProductHits = connectHits(ProductHits);
  const CustomRecipeHits = connectHits(RecipeHits);

  useEffect(() => {
    if (isBrowser()) {
      window.scrollTo({ top: 0 });
    }
  });

  return (
    <>
      <SEO seoData={null} />
      <Layout>
        <InstantSearch indexName="Blogs" searchClient={searchClient}>
          <Configure hitsPerPage={1000} />

          <HiddenSearchBar defaultRefinement={headerSearch} />

          <Container flexDirection="column" width="65%" margin="220px auto" tabletMargin="120px auto" tabletWidth="90%">
            {hitsVisible ? (
              <Container>
                <H2 margin={!isLaptopWidth ? "0 0 30px 0" : "0 0 80px 0"} color="darkerGreen">
                  Search Results for '{headerSearch}'
                </H2>
              </Container>
            ) : (
              <Container flexDirection="column">
                <H2 margin={!isLaptopWidth ? "0 0 30px 0" : "0 0 30px 0"} color="darkerGreen">
                  No Search Results for '{headerSearch}'
                </H2>
                <Container>
                  <Button linkTo="/" text="Back to Home" theme="block" />
                  <Button margin="0 0 0 10px" linkTo="/recipes" text="View Recipes" theme="block" />
                </Container>
              </Container>
            )}
            <Index indexName="Recipes">
              <CustomRecipeHits />
            </Index>
            <Index indexName="Products">
              <CustomProductHits />
            </Index>
            <Index indexName="Blogs">
              <CustomBlogHits />
            </Index>
            <Index indexName="Feed Your Life Pages">
              <CustomFeedYourLifeHits />
            </Index>
          </Container>
        </InstantSearch>
      </Layout>
    </>
  );
};

export default SearchResults;
