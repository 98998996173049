import React from "react";

import { SanityBlog, SanityFeedYourLifeInner, SanityProduct, SanityRecipe } from "@graphql-types";
import { A, Container, H4, P } from "@util/standard";
import { isSanityBlog, isSanityFeedYourLifeInner, isSanityProduct, isSanityRecipe } from "@util/types";
import { colorsRGB } from "@util/constants";

interface Props {
  data?: SanityProduct | SanityBlog | SanityFeedYourLifeInner | SanityRecipe;
  noBorder?: boolean;
}

function SearchResultPreview({ data, noBorder }: Props) {
  if (data == null) {
    return null;
  }

  if (isSanityFeedYourLifeInner(data)) {
    return (
      <Container
        justifyContent="space-between"
        borderBottom={noBorder ? "" : `1px solid ${colorsRGB.black(0.4)}`}
        padding="0 0 30px 0"
      >
        <H4 margin="50px 0 0 0" fontWeight="normal">
          {data.pageName}
        </H4>
        <A margin="50px 0 0 0" bold href={`/feed-your-life/${data.slug?.current}`} underlined>
          Go to page
        </A>
      </Container>
    );
  }

  if (isSanityProduct(data)) {
    return (
      <Container
        flexDirection={data.aboutProduct ? "column" : "row"}
        justifyContent={data.aboutProduct ? "" : "space-between"}
        borderBottom={noBorder ? "" : `1px solid ${colorsRGB.black(0.4)}`}
        padding="0 0 30px 0"
      >
        <H4 margin="50px 0 0 0" fontWeight="normal">
          {data.productName}
        </H4>
        {data.aboutProduct ? (
          <Container justifyContent="space-between">
            <P width="80%">{data.aboutProduct}</P>
            <Container flexDirection="column" justifyContent="flex-end">
              <A bold href={`/products/${data.slug?.current}`} underlined>
                Go to page
              </A>
            </Container>
          </Container>
        ) : (
          <A bold href={`/products/${data.slug?.current}`} underlined>
            Go to page
          </A>
        )}
      </Container>
    );
  }
  if (isSanityRecipe(data)) {
    return (
      <Container
        flexDirection={data.description ? "column" : "row"}
        justifyContent={data.description ? "" : "space-between"}
        borderBottom={noBorder ? "" : `1px solid ${colorsRGB.black(0.4)}`}
        padding="0 0 30px 0"
      >
        <H4 margin="50px 0 0 0" fontWeight="normal">
          {data.recipeName}
        </H4>
        {data.description ? (
          <Container justifyContent="space-between">
            <P width="80%">{data.description}</P>
            <Container flexDirection="column" justifyContent="flex-end">
              <A bold href={`/recipes/${data.slug?.current}`} underlined>
                Go to page
              </A>
            </Container>
          </Container>
        ) : (
          <A bold href={`/recipes/${data.slug?.current}`} underlined>
            Go to page
          </A>
        )}
      </Container>
    );
  }

  if (isSanityBlog(data)) {
    return (
      <Container
        padding="0 0 30px 0"
        justifyContent="space-between"
        borderBottom={noBorder ? "" : `1px solid ${colorsRGB.black(0.4)}`}
      >
        <H4 margin="50px 0 0 0" fontWeight="normal">
          {data.blogTitle}
        </H4>
        <A margin="50px 0 0 0 " bold href={`/learn/${data.slug?.current}`} underlined>
          Go to page
        </A>
      </Container>
    );
  }
  return null;
}

export default SearchResultPreview;
